.topContainer {
  background-color: #f3eddc;
  padding: 4rem 0;

  @media screen and (max-width: 1024px) {
    padding-top: 1rem;
  }
}

.reviewList {
  .review {
    margin-left: 15px;
    margin-right: 15px;
    &.halfWidth {
      max-width: 49%;
      grid-column: span 2;
      // max-width: 49%;
      margin: auto;

      @media screen and (max-width: 1024px) {
        max-width: 100%;
        margin-left: 15px;
        margin-right: 15px;
      }
    }

    a {
      text-decoration: none;
    }

    p {
      width: 100%;
      color: #9b9b9b;
      font-size: 0.813rem;
    }
  }
}

.carouselWrapper {
  width: 100%;

  .imageInline {
    figcaption {
      bottom: -4px !important;
    }
  }
}

div.scrollableContent {
  width: 53%;
  max-width: 888px;
  margin: auto;

  .content {
    font-size: 1rem;
    font-weight: 400 !important;
  }

  p,
  ul,
  ol,
  h3,
  span {
    padding: 0;
    margin: 0;
    width: 100%;
    font-family: Libre Franklin, sans-serif !important;
    font-size: 1rem;
  }

  ul {
    padding: 0 1rem;
  }

  strong {
    font-weight: 600;
  }
}

.article {
  .overline {
    font-size: 0.938rem !important;
    color: #9b9b9b;
    line-height: 160% !important;
    text-align: center;
    margin-top: 3rem;
  }

  :global {
    summary {
      &::marker {
        display: none !important;
        list-style-type: none !important;
      }
    }
  }

  .title {
    max-width: 85%;
    margin: auto;
    font-size: 3.125rem;
    line-height: 140%;
    margin-bottom: 1.5rem;
    text-align: center;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
      font-size: 2.188rem;
      line-height: 120%;
    }
  }

  .authorBioContainer {
    width: 100%;
    padding: 0 1rem;
    margin-top: 3rem;
  }

  .articleMeta {
    display: flex;
    justify-content: center;
  }

  figure {
    padding-top: 2em;

    figcaption {
      font-size: 0.813rem;
      line-height: 0.8125rem;
      color: rgba(32, 32, 32, 0.6);
      margin-top: 0.5rem;
      z-index: 2;

      .carouselLink {
        padding-left: 0px;
      }
    }

    video {
      width: 100%;
    }
  }

  .carouselLink {
    position: absolute;
    z-index: 2 !important;
    top: -10px;

    @media screen and (max-width: 450px) {
      padding-left: 20px;
    }
  }

  .youtubeIframe {
    max-width: 888px;
    @media screen and (max-width: 711px) {
      max-width: 444px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1300px) {
      max-width: 711px;
    }
    @media screen and (min-width: 711px) and (max-width: 1024px) {
      max-width: 711px;
    }
    height: 500px;
    @media screen and (min-width: 1024px) and (max-width: 1300px) {
      height: 400px;
    }
    @media screen and (min-width: 711px) and (max-width: 1024px) {
      height: 400px;
    }
    @media screen and (max-width: 711px) {
      height: 250px;
    }
    margin: auto;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .imageInline,
  .carouselWrapper {
    max-width: 100%;

    figure {
      min-height: 100%;
    }

    margin: 2rem auto 2.2rem;
    text-align: center;
    position: relative;
    @media screen and (max-width: 539px) {
      margin: 0 1.25rem;
      margin-bottom: 35px;
    }

    figcaption {
      width: 100%;
      bottom: -16px;
      position: absolute;
      text-align: left;
    }
  }

  .imageInline {
    figcaption {
      @media screen and (min-width: 381px) and (max-width: 711px) {
        bottom: -15px;
      }

      @media screen and (max-width: 380px) {
        bottom: -3px;
      }
    }
  }

  .mainImage {
    margin-top: 1rem;
    text-align: center;
    position: relative;
    height: 500px;
    @media screen and (max-width: 1024px) {
      height: 250px;
    }
  }

  a {
    color: #202020;
    text-decoration: underline;
  }

  p,
  ul,
  ol,
  blockquote {
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    width: 53%;
    font-size: 0.938rem;
    line-height: 160%;
    font-weight: 400;
    color: #202020;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    margin: auto auto 1rem;

    strong {
      font-weight: 700;
    }
  }

  ul {
    list-style: disc;
    padding-left: 35px;
  }

  ol {
    list-style: decimal;
    padding-left: 35px;
  }

  .flightSearch {
    margin: 2rem 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 0 1px #e8e8e8 inset;
  }

  blockquote {
    border-left: 3px solid grey;

    p {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }
}

// CTA block

.cta {
  text-align: center;
  padding: 30px;
  width: 99vw;
  position: relative;
  right: 50%;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: linear-gradient(rgba(0, 47, 74, 0.5), rgba(0, 47, 74, 0.5)),
    url(/_next/static/media/deals-bg.e9050f1b.webp) no-repeat;
  background-size: 300%;
  background-position: 75% 6%;
  margin-top: 20px;
  margin-bottom: 20px;

  .ctaHeader {
    text-align: center;
    color: #fff;
    font-size: 40px;
    line-height: 130%;
  }

  .ctaSubheader {
    text-align: center;
    color: #fff;
    color: #fff;
    font-size: 19px;
  }

  .ctaButton {
    display: inline-block;
    background: #d4a976;
    color: #fff;
    font-size: 1rem;
    padding: 16px 20px;
    border-radius: 10px;
    text-decoration: none;
    margin-top: 20px;
  }
}

.closeDialog {
  z-index: 1;
  border-radius: 50%;
  background-color: #D4A976 !important;
  color: white !important;
  margin-bottom: 0.5rem;
  width: 40px;
  height: 40px;

  &:hover {
    background-color: #86694B !important;
  }
}

.offerDetailsContainer {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.02);
}


.noOffers {
  background-color: #fff;
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.02);
  padding: 20px;
  text-align: center;
  margin-bottom: 1rem;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
  }
}

.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(1020px - 2rem);
  padding: 1rem;
  justify-content: center;
  margin: auto;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }

  .filter {
    min-width: 300px;
    margin: 0 1rem;
    background-color: #FFFFFF;
    border-radius: 12px;
    @media screen and (max-width: 1024px) {
      min-width: 100%;
      margin-bottom: 10px;
    }
  }

  .filterSmaller {
    min-width: 200px;
    margin: 0 1rem;
    background-color: #FFFFFF;
    border-radius: 12px;

    @media screen and (max-width: 1024px) {
      min-width: 100%;
      margin-bottom: 10px;
    }
  }

  .filtersSubmit {
    min-width: 200px;
  }
}